<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <span><strong>Ayarlar</strong></span>
          </CCardHeader>

          <CCardBody>
            <CRow>
              <CCol class="col-12">
                <CRow
                  class="cursor-pointer mb-3"
                  @click="carResultPageCollapsed = !carResultPageCollapsed">
                  <CCol>
                    <div class="float-left"><h5>Araç Sonuçları</h5></div>
                    <div class="float-right">
                      <font-awesome-icon
                        :icon="['fas', 'chevron-up']"
                        size="lg"
                        v-if="carResultPageCollapsed" />
                      <font-awesome-icon
                        :icon="['fas', 'chevron-down']"
                        size="lg"
                        v-else />
                    </div>
                  </CCol>
                </CRow>

                <CCollapse :show="carResultPageCollapsed">
                  <CRow>
                    <CCol class="col-md-3 col-12">
                      <CSelect
                        label="Toplam Araç Yazısı"
                        :value.sync="resultPageCarCountForm.status"
                        :options="totalCarStatusOptions" />
                    </CCol>

                    <CCol class="col-md-3 col-12">
                      <CInput
                        label="Toplam Araç Yazısı"
                        v-model="resultPageCarCountForm.text" />
                      <small
                        >Örn: Toplam {TOTAL} adet araç içerisinden, {VIEW} adet
                        araç listelenmektedir.
                      </small>
                    </CCol>
                    <CCol class="col-md-3 col-12">
                      <CSelect
                        label="Partner Promosyon Kodu"
                        :value.sync="partnerPromoCodeStatusDefault"
                        :options="partnerPromoCodeStatus" />
                    </CCol>

                    <CCol class="col-12 text-right">
                      <CButton color="success" @click="resultPageUpdate"
                        >Kaydet</CButton
                      >
                    </CCol>
                  </CRow>
                </CCollapse>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol class="col-12">
                <CRow
                  class="cursor-pointer mb-3"
                  @click="subSitePageCollapsed = !subSitePageCollapsed">
                  <CCol>
                    <div class="float-left"><h5>Alt Site Ekle</h5>
                    </div>
                    <div class="float-right">
                      <font-awesome-icon
                        :icon="['fas', 'chevron-up']"
                        size="lg"
                        v-if="subSitePageCollapsed" />
                      <font-awesome-icon
                        :icon="['fas', 'chevron-down']"
                        size="lg"
                        v-else />
                    </div>
                  </CCol>
                </CRow>

                <CCollapse :show="subSitePageCollapsed">
                  <CRow>
                    <CCol class="col-md-3 col-12">
                      <CInput
                        label="Alt-Site İsim"
                        v-model="subSiteAddForm.name" />
                    </CCol>
                    <CCol class="col-md-3 col-12">
                      <CInput
                        label="Alt-Site Url"
                        v-model="subSiteAddForm.url" />
                    </CCol>

                    <CCol class="col-md-3 col-12">
                      <v-select
                        v-model="subSiteAddForm.status"
                        :items="subSiteStatus"
                        label="Alt-Site Durumu"
                        item-text="label"
                        item-value="value">
                      </v-select>
                    </CCol>

                    <CCol class="col-md-3 col-12">
                      <v-select
                        v-model="subSiteAddForm.partnerId"
                        label="Partner Seçimi"
                        :items="partners"
                        item-text="partnerValue.name"
                        item-value="partnerValue.id">
                      </v-select>
                    </CCol>

                    <CCol class="col-12 text-right">
                    <!-- <div   v-for="(data, i) in subSites"
                :key="i">
                      {{ data }}
                    </div> -->
                      <CButton class="mr-4" color="success" @click="showSubSite()"
                        >Alt Siteleri Göster</CButton  >
                       
                      <CButton color="success" @click="subSiteAdd()"
                        >Kaydet</CButton
                      ></CCol>
                    
                  </CRow>
                </CCollapse>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>



<script>
import { parseSync } from "@babel/core";
import axios from "axios";
import Vue from "vue";
export default {
  name: "Options",
  data() {
    return {
      carResultPageCollapsed: true,
      subSitePageCollapsed: true,
      // subSiteStatusDefault:false,
      partnerPromoCodeStatusDefault: false,
      // SELECT OPTİONS
      totalCarStatusOptions: [
        {
          value: true,
          label: "Göster",
        },
        {
          value: false,
          label: "Gösterme",
        },
      ],
      partnerPromoCodeStatus: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      subSiteStatus: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      resultPageCarCountForm: {
        status: true,
        text: "",
      },
      subSiteAddForm: {
        name: "",
        status: false,
        url: "",
        partnerId: "",
      },
      partners: [],
    };
  },
  created() {
    this.getPartners();
  },
  methods: {
    resultPageUpdate() {
      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/settings",
          {
            resultPageCarCount: this.resultPageCarCountForm,
            partnerPromoCodeStatus: this.partnerPromoCodeStatusDefault,
          },
          { withCredentials: true }
        )
        .then((response) => {});
    },
    async getPartners() {
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "admin/partner"
        );
        this.partners = data.map((item) => {
          return {
            ...item,
            partnerValue: {
              name: item.title,
              id: item._id,
            },
          };
        });
      } catch (error) {
        console.log(error);
      }
    },  
    async showSubSite(){
      try {
      const subSites = await axios.get(
        process.env.VUE_APP_API_URL + "admin/subsites",   
      );
this.subSites=subSites.data.subSites;
console.log("subsSites",this.subSites)
    } catch (e) {
      console.log(e.message);
    }
    },
    async subSiteAdd(){
      axios.post(
          process.env.VUE_APP_API_URL + "admin/subsites",
          {
            params:this.subSiteAddForm
          }).then((response) => {
            if (response.data.result === "success"){
              Vue.swal({
              icon: response.data.result,
              title: "Subsite Eklendi",
              html: response.data.message,
              confirmButtonText: "Tamam",
            });
            }else{
              Vue.swal({
              icon: response.data.result,
              title: "Subsite Eklenemedi!!",
              html: response.data.message,
              confirmButtonText: "Tamam",
            });
            }
          }
    )} 

}
};
</script>

<style scoped></style>
